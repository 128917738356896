import React, { useContext } from 'react';
import LanguageContext from '../context/context';
import useRecruitment from '../hooks/useRecruiting';
import styled from '@emotion/styled';

const Recruitment = ({ isOpen }) => {
	const { link_to_jobs, recruiting_text, recruiting_text_EN, show_on_page } =
		useRecruitment();
	const { language } = useContext(LanguageContext);
	const RecruitmentContainer = styled.div`
		display: flex;
		position: absolute;
		top: 100%;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: fit-content;
		background-color: white;
		border-top: solid 0.4rem #dadadc;
		border-bottom: solid 0.4rem #dadadc;
		/*margin-top: 0.5rem;*/
		h3 {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.3rem 0;
			width: 100%;
			margin: 0;

			a {
				display: flex;
				width: 100%;
				height: 100%;
				font-family: 'Encode Sans', sans-serif;
				color: #0054a5;
				font-weight: bold;
				align-items: center;
				justify-content: center;
				transition: all 0.2s ease-in-out;
			}
			a:hover {
				background-color: #0054a5;
				color: white;
			}
		}
	`;

	if (show_on_page === true && isOpen === false) {
		return (
			<RecruitmentContainer className="recruitment">
				<h3>
					<a href={`#${link_to_jobs}`}>
						{language === 'DE' ? recruiting_text : recruiting_text_EN}
					</a>
				</h3>
			</RecruitmentContainer>
		);
	} else {
		return null;
	}
};

export default Recruitment;
