import React, { useContext, useState, useEffect } from 'react';
import { VisibleContext } from '../context/VisibleContext';
import LanguageContext from '../context/context';
import useNavigation from '../hooks/useNavigation';
import Recruitment from './Recruitment';
import * as _ from 'lodash';

import {
	NavigationContainer,
	NavigationMenu,
	NavigationList,
	LanguageSwitcher,
	LanguageButton,
	MobileNavigationMenu,
	MobileNavigationList,
	MobileTitle,
} from './Navigation-Components';

import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';

const Navigation = () => {
	const { language, handleChangeLanguage } = useContext(LanguageContext);
	const result = useNavigation();
	const [navSections, setNavSections] = useState([]);
	const [activeSection, setActiveSection] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [homeID, setHomeID] = useState();
	const { sectionOnScreen } = useContext(VisibleContext);
	const [screenWidth, setScreenWidth] = useState();

	useEffect(() => {
		setNavSections(result);
	}, []);

	useEffect(() => {
		setScreenWidth(window.matchMedia('(min-width: 524px)').matches);
	}, []);

	useEffect(() => {
		window
			.matchMedia('(min-width: 524px)')
			.addEventListener('change', e => setScreenWidth(e.matches));
	}, []);

	useEffect(() => {
		if (screenWidth === true) {
			setHomeID('header');
		} else {
			setHomeID('home');
		}
	}, [screenWidth]);

	const handleMobileClick = () => {
		setIsOpen(false);
	};

	const HamburguerButton = (
		<GiHamburgerMenu
			className="mobile-nav-icon"
			size="35px"
			color="#004ea2"
			onClick={() => setIsOpen(!isOpen)}
		/>
	);

	const CloseButton = (
		<AiOutlineClose
			className="mobile-nav-icon"
			size="35px"
			color="#004ea2"
			onClick={() => setIsOpen(!isOpen)}
		/>
	);
	return (
		<NavigationContainer className="navigation">
			<NavigationMenu className="navigation-menu">
				<NavigationList>
					<li
						className={activeSection === 'home' ? 'nav-active' : null}
						onClick={() => setActiveSection('home')}
					>
						<span className="arrow-nav">&#8811; </span>
						<a
							href={`#${homeID}`}
							className={sectionOnScreen === 'home' ? 'is-on-screen' : null}
						>
							Home
						</a>
					</li>
					{_.sortBy([...navSections], ['nav_menu_position']).map(section => (
						<li
							key={section.nav_id}
							className={activeSection === section.nav_id ? 'nav-active' : null}
							onClick={() => setActiveSection(section.nav_id)}
						>
							<span className="arrow-nav">&#8811; </span>
							<a
								href={`#${section.nav_id}`}
								className={
									sectionOnScreen === `${section.nav_id}-scroll-target`
										? 'is-on-screen'
										: null
								}
							>
								{language === 'DE' ? section.title : section.titleEN}
							</a>
						</li>
					))}
				</NavigationList>
			</NavigationMenu>
			<MobileNavigationMenu className="mobile-navigation-menu">
				{isOpen ? CloseButton : HamburguerButton}
				<MobileNavigationList
					className={isOpen ? 'mob-nav-open' : 'mob-nav-closed'}
				>
					<li
						className={activeSection === 'home' ? 'nav-active' : null}
						onClick={() => handleMobileClick()}
					>
						{/* <span className="arrow-nav">&#8811; </span> */}
						<a
							href={`#${homeID}`}
							className={
								sectionOnScreen === 'home' ? 'section-on-screen' : null
							}
						>
							Home
						</a>
					</li>
					{_.sortBy([...navSections], ['nav_menu_position']).map(section => (
						<li
							key={section.nav_id}
							className={activeSection === section.nav_id ? 'nav-active' : null}
							onClick={() => handleMobileClick()}
						>
							{/* <span className="'arrow-nav'">&#8811; </span> */}
							<a href={`#${section.nav_id}`}>
								{language === 'DE' ? section.title : section.titleEN}
							</a>
						</li>
					))}
				</MobileNavigationList>
			</MobileNavigationMenu>
			<LanguageSwitcher className="language-switcher">
				<LanguageButton
					className={language === 'DE' ? 'active' : null}
					onClick={() => handleChangeLanguage('DE')}
				>
					<p>
						<span className="'arrow-lang'">&#8811; </span> DE
					</p>
				</LanguageButton>
				<LanguageButton
					className={language === 'EN' ? 'active' : null}
					onClick={() => handleChangeLanguage('EN')}
				>
					<p>
						<span className="'arrow-lang'">&#8811; </span> EN
					</p>
				</LanguageButton>
			</LanguageSwitcher>
			<MobileTitle className="header-title-mobile">
				<a href="#header">
					flow<span>Tools</span>
				</a>
			</MobileTitle>
			<Recruitment isOpen={isOpen} />
		</NavigationContainer>
	);
};

export default Navigation;
