import { graphql, useStaticQuery } from 'gatsby';

const useNavigation = () => {
	const result = useStaticQuery(
		graphql`
			query {
				allStrapiPageSection(filter: { in_nav_menu: { eq: true } }) {
					nodes {
						Title
						Title_EN
						nav_id
						nav_menu_position
						sub_sections {
							id
							title
							title_EN
							nav_id
						}
					}
				}
			}
		`,
	);

	return result.allStrapiPageSection.nodes.map(menuSection => ({
		title: menuSection.Title,
		titleEN: menuSection.Title_EN,
		nav_id: menuSection.nav_id,
		nav_menu_position: menuSection.nav_menu_position,
		sub_sections: menuSection.sub_sections,
	}));
};

export default useNavigation;
