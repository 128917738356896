import { graphql, useStaticQuery } from 'gatsby';

const useHome = () => {
	const result = useStaticQuery(graphql`
		query {
			allStrapiHomepage {
				nodes {
					home_image {
						file {
							childImageSharp {
								gatsbyImageData(width: 1280, blurredOptions: { toFormat: PNG })
							}
						}
					}
				}
			}
		}
	`);

	return result.allStrapiHomepage.nodes[0];
};

export default useHome;
