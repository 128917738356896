import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import SectionsList from '../components/SectionsList';
import LanguageContext, { languages } from '../context/context';
import { VisibleContext } from '../context/VisibleContext';
import Home from '../components/Home';
import * as logo from '../images/favicon.png';

export default function Index() {
	const [language, setLanguaje] = useState(languages.DE);
	const [sectionsToWatch, setSectionsToWatch] = useState([]);
	const [sectionOnScreen, setSectionOnScreen] = useState(null);

	const handleChangeLanguage = lan => {
		if (lan === language) {
			return;
		}
		setLanguaje(lan);
	};

	//Create a constant that will be used to define the body element of the whole site

	let observer;

	useEffect(() => {
		observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					entry.target.className += ' section-on-screen';

					setSectionOnScreen(entry.target.id);
				});
			},
			{
				root: null,
				threshold: 0.01,
				rootMargin: '-30% 0% -47% 0%',
			},
		);
	}, [sectionsToWatch]);

	useEffect(() => {
		sectionsToWatch.forEach(section => {
			observer.observe(document.querySelector(section));
		});
	}, [sectionsToWatch]);

	return (
		<LanguageContext.Provider
			value={{ languages, language, handleChangeLanguage }}
		>
			<VisibleContext.Provider
				value={{ sectionsToWatch, setSectionsToWatch, sectionOnScreen }}
			>
				<Helmet>
					<meta
						name="keywords"
						content="SAP, ABAP, FIORI, entwicklung, programmierung, logistik, Freiburg"
					/>
					<meta
						name="description"
						content="Seit über 15 Jahren führt flowTools erfolgreich SAP Projekte durch. Ein Team aus erfahrenen Entwicklern konzipiert und programmiert Anwendungen im Umfeld Ihrer SAP Systeme. In enger Absprache mit Ihnen werden diese Tools individualisert und von flowTools weiter entwickelt."
					/>
					<meta name="language" content="de-DE" />
					<meta name="author" content="Pablo Núñez Ruiz" />
					<title>flowTools</title>
					<link rel="canonical" href="https://flowtools.de" />
				</Helmet>
				<Layout language={language}>
					<Home />
					<SectionsList language={language} />
				</Layout>
			</VisibleContext.Provider>
		</LanguageContext.Provider>
	);
}
