import React, { useContext, useState, useEffect } from 'react';
import LanguageContext from '../context/context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Description from './Description';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '../theme/theme';

const Subsection = ({ subsection, themeColor }) => {
	const { language } = useContext(LanguageContext);
	const {
		subsection_img,
		title,
		title_EN,
		subtitle,
		subtitle_EN,
		description_text,
		description_text_EN,
		nav_id,
		link_to,
		reference_links,
	} = subsection;

	const SubsectionImage = getImage(
		subsection_img?.file.childImageSharp.gatsbyImageData,
	);

	const [descriptionText, setDescriptionText] = useState();

	useEffect(() => {
		if (language === 'DE') {
			setDescriptionText(description_text);
		} else {
			setDescriptionText(description_text_EN);
		}
	}, [language]);

	return (
		<SubsectionContainer>
			<div className="anchor" id={nav_id}>
				anchor
			</div>
			<div id={`${nav_id}-scroll-target`} className="intersection-area-sub">
				intersection target subsection
			</div>
			{subsection_img !== null ? (
				<GatsbyImage
					css={css`
						width: 100%;
					`}
					image={SubsectionImage}
					alt={title}
				/>
			) : null}
			{title === '' ? null : (
				<TitleContainer
					className={`${nav_id}-title-container`}
					css={css`
						color: ${themeColor};
					`}
				>
					<h2 className={`${nav_id}-title sub-section-title`}>
						{language === 'DE' ? title : title_EN}
					</h2>
					{subtitle === null ? null : (
						<h3 className={`${nav_id}-subtitle sub-section-subtitle`}>
							{language === 'DE' ? subtitle : subtitle_EN}
						</h3>
					)}
				</TitleContainer>
			)}
			{description_text === '' ? null : (
				<Description title={title} text={description_text} />
			)}
			{reference_links !== null ? (
				<ReferenceLinksContainer>
					{reference_links.map(link => (
						<li key={`${link.id}`}>
							<a href={link.link} target="_blank" rel="noreferrer">
								{link.linktitle} <span>&#8811;</span>
							</a>
							<p>
								{link.reference_text} <br />{' '}
								<strong>
									<a href={link.link} target="_blank" rel="noreferrer">
										{link.link}
									</a>
								</strong>{' '}
							</p>
						</li>
					))}
				</ReferenceLinksContainer>
			) : null}
			{link_to === null ? null : (
				<LinkToContainer>
					<li>
						<a href={`#${link_to.nav_id}`} color={themeColor}>
							<span className={`${nav_id}-link-arrow link-to-arrow`}>
								&#8811;{' '}
							</span>
							{language === 'DE' ? link_to.Title : link_to.Title_EN}
						</a>
					</li>
				</LinkToContainer>
			)}
		</SubsectionContainer>
	);
};

const SubsectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: fit-content;
	justify-content: space-between;
	background-color: white;
	margin: 0.5rem 0;
	position: relative;

	.anchor {
		position: absolute;
		top: -9rem;
		opacity: 0;
		@media screen and (max-width: 525px) {
			top: -12rem;
		}
	}
	.intersection-area-sub {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -2;
		opacity: 0;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 2.5rem 4rem;
`;

const ReferenceLinksContainer = styled.ul`
	padding: 2rem 4rem;
	margin-bottom: 2rem;
	li {
		margin: 3rem 0;
		a {
			color: ${colors.themeBlue};
			font-weight: 700;
			font-size: 3rem;
		}
		a:hover {
			color: ${colors.themeBlue};
			span {
				display: inline-block;
				font-size: 1.5rem;
			}
		}
		p > strong > a {
			font-size: 2.2rem;

			&:hover {
				color: #0054a5;
				cursor: pointer;
			}
		}
		@media (min-width: 525px) {
			span {
				display: none;
			}
		}
	}
`;

const LinkToContainer = styled.ul`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: end;
	padding-left: 1rem;
	margin-bottom: 2rem;

	li {
		display: flex;
		width: 50%;

		a {
			display: flex;
			font-weight: 600;
			text-align: left;
			text-decoration: none;
			text-transform: uppercase;
			color: #807974;
			transition: color 0.2s ease-in-out;

			.link-to-arrow {
				font-size: 1.5rem;
				line-height: 4rem;
				font-weight: 700;
				margin-right: 1rem;
				transition: transform 0.2s ease-in-out;
			}

			&:hover {
				color: ${colors.themeBlue};
				.link-to-arrow {
					transform: translateX(0.5rem);
				}
			}
		}
	}
`;

export default Subsection;
