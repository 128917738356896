import { colors } from '../theme/theme';

export const subSecColor = order => {
	if (order === 1) {
		return colors.subsection1;
	}
	if (order === 2) {
		return colors.subsection2;
	}
	if (order === 3) {
		return colors.subsection3;
	}
	if (order === 4) {
		return colors.subsection4;
	}
	if (order === 5) {
		return colors.subsection5;
	}
	if (order === 6) {
		return colors.subsection6;
	}
};
