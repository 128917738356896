import React, { useState, useEffect, useContext } from 'react';
import { VisibleContext } from '../context/VisibleContext';
import useSections from '../hooks/useSections';
import PageSection from './PageSection';
import * as _ from 'lodash';

const SectionsList = () => {
	const result = useSections();
	const [sections, setSections] = useState([]);
	const { setSectionsToWatch } = useContext(VisibleContext);

	useEffect(() => {
		setSections(result);
	}, []);

	useEffect(() => {
		const converted = sections.map(section => {
			return `#${section.nav_id}-scroll-target`;
		});

		setSectionsToWatch(converted);
		setSectionsToWatch(sectionsToWatch => [...sectionsToWatch, '#home']);
	}, [sections]);

	return (
		<div className="sections-list">
			{_.sortBy([...sections], ['position']).map(section => (
				<PageSection key={section.id} section={section} />
			))}
		</div>
	);
};

export default SectionsList;
