import React, { useEffect } from 'react';
import { useBewerbungsKontakt } from '../hooks/useBewerbungsKontakt';
import styled from '@emotion/styled';
import { colors } from '../theme/theme';

const BewerbungsKontakt = () => {
	const result = useBewerbungsKontakt();
	const {
		email_text,
		email_adress,
		telephone_text,
		telephone_number_to_show,
		telephone_number_to_call,
	} = result;

	return (
		<BewerbungsKontaktContainer>
			<p>
				{email_text} <a href={`mailto:${email_adress}`}>{email_adress}</a>
			</p>
			<p>
				{telephone_text}{' '}
				<a href={`tel:${telephone_number_to_call}`}>
					{telephone_number_to_show}
				</a>
			</p>
		</BewerbungsKontaktContainer>
	);
};

const BewerbungsKontaktContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 90%;
	padding: 2rem 4rem;
	margin-bottom: 2rem;

	p {
		font-weight: 500;
		font-size: 2.3rem;
	}

	a {
		color: ${colors.themeBlue};

		&:hover {
			text-decoration: underline;
		}
	}
`;

export default BewerbungsKontakt;
