import React, { useContext } from 'react';
import LanguageContext from '../context/context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Subsection from './Subsection';
import Description from './Description';
import Stellenangebot from './Stellenangebot';
import BewerbungsKontakt from './BewerbungsKontakt';
import * as _ from 'lodash';
import styled from '@emotion/styled';
import { subSecColor } from '../hooks/useColors';
import { css } from '@emotion/react';
import { colors } from '../theme/theme';

const PageSection = ({ section }) => {
	const {
		title,
		titleEN,
		subtitle,
		subtitleEN,
		description,
		descriptionEN,
		kontakt,
		impressum,
		nav_id,
		reference_links,
		image,
		link_to,
		sub_sections,
		stellenangebots,
	} = section;

	const sectionImage = getImage(image?.file.childImageSharp.gatsbyImageData);
	const { language } = useContext(LanguageContext);

	return (
		<PageSectionContainer className={`page-section ${nav_id}`}>
			<div className="anchor" id={nav_id}>
				anchor
			</div>
			<div
				id={`${section.nav_id}-scroll-target`}
				className={
					section.sub_sections === null
						? 'intersection-area'
						: 'intersection-area-with-subsections'
				}
			>
				intersection target Section
			</div>
			{language === 'DE' ? (
				<div
					css={css`
						width: 100%;
					`}
					className={impressum !== null ? 'kontakt-impressum' : null}
				>
					{image !== null ? (
						<GatsbyImage
							css={css`
								width: 100%;
							`}
							image={sectionImage}
							alt={title}
						/>
					) : null}
					<TitleContainer className={`${nav_id}-title-container`}>
						<h2 className={`${nav_id}-title page-section-title`}>{title}</h2>
						{subtitle === null ? null : (
							<h3 className={`${nav_id}-subtitle page-section-subtitle`}>
								{subtitle}
							</h3>
						)}
					</TitleContainer>

					{kontakt === null ? null : (
						<ul className="kontakt">
							<li>
								<p>{kontakt.Firma}</p>
							</li>
							<li>
								<p>{kontakt.Adresse}</p>
							</li>
							<li>
								<p>Tel. {kontakt.Telefon}</p>
							</li>
							<li>
								<p>Fax {kontakt.Fax}</p>
							</li>
							<li>
								<p>{kontakt.email}</p>
							</li>
							<li>
								<p>{kontakt.Website}</p>
							</li>
						</ul>
					)}

					{impressum === null ? null : (
						<div className="impressum">
							<h4>{impressum.Titel}</h4>
							{impressum.Inhalt.split(`\n`).map(impressumSection => (
								<p key={Math.random()}>
									{impressumSection}
									<br />
								</p>
							))}
						</div>
					)}
					{reference_links !== null ? (
						<ReferenceLinksContainer>
							{reference_links.map(link => (
								<li key={link.id}>
									<a href={`${link.link}`} target="_blank" rel="noreferrer">
										{link.linktitle} <span>&#8811;</span>
									</a>

									<p>{link.reference_text}</p>
								</li>
							))}
						</ReferenceLinksContainer>
					) : null}
					{description === '' || title === 'IMPRESSUM' ? null : (
						<Description title={title} text={description} />
					)}
					{link_to !== null ? (
						<LinkToContainer>
							{link_to.map(link => (
								<li key={link.nav_id}>
									<a href={`#${link.nav_id}`}>
										<span className={`${nav_id}-link-arrow link-to-arrow`}>
											&#8811;{' '}
										</span>
										{link.Title}
									</a>
								</li>
							))}
						</LinkToContainer>
					) : null}
				</div>
			) : (
				<div className={impressum !== null ? 'kontakt-impressum' : null}>
					{image !== null ? (
						<GatsbyImage image={sectionImage} alt={titleEN} />
					) : null}
					<TitleContainer id={nav_id}>
						<h2 className={`${nav_id}-title`}>{titleEN}</h2>
						<h3 className={`${nav_id}-subtitle`}>{subtitleEN}</h3>
					</TitleContainer>

					{kontakt === null ? null : (
						<ul className="kontakt">
							<li>
								<p>{kontakt.Firma}</p>
							</li>
							<li>
								<p>{kontakt.Adresse}</p>
							</li>
							<li>
								<p>Tel. {kontakt.Telefon}</p>
							</li>
							<li>
								<p>Fax {kontakt.Fax}</p>
							</li>
							<li>
								<p>{kontakt.email}</p>
							</li>
							<li>
								<p>{kontakt.Website}</p>
							</li>
						</ul>
					)}
					{descriptionEN === null ? null : <Description text={descriptionEN} />}
					{impressum === null ? null : (
						<div className="impressum">
							<h4>{impressum.Titel_EN}</h4>
							{impressum.Inhalt_EN.split(`\n`).map(impressumSectionEN => (
								<p key={Math.random()}>{impressumSectionEN}</p>
							))}
						</div>
					)}
					{reference_links !== null ? (
						<ReferenceLinksContainer>
							{reference_links.map(link => (
								<li key={`${link.id}EN`}>
									<a href={link.link} target="_blank" rel="noreferrer">
										{link.linktitle} <span>&#8811;</span>
									</a>
									<p>{link.reference_text_EN}</p>
								</li>
							))}
						</ReferenceLinksContainer>
					) : null}
					{link_to !== null ? (
						<LinkToContainer>
							{link_to.map(link => (
								<li key={link.nav_id}>
									<a href={`#${link.nav_id}`}>
										<span className={`${nav_id}-link-arrow link-to-arrow`}>
											&#8811;{' '}
										</span>
										{link.Title_EN}
									</a>
								</li>
							))}
						</LinkToContainer>
					) : null}
				</div>
			)}
			{sub_sections !== null
				? _.sortBy([...sub_sections], ['Order']).map(subsection => {
						return (
							<Subsection
								subsection={subsection}
								key={subsection.id}
								themeColor={subSecColor(subsection.Order)}
							/>
						);
				  })
				: null}
			{stellenangebots !== null
				? _.sortBy([...stellenangebots], ['stelle_ID']).map(stelleangebot => {
						return (
							<Stellenangebot
								stelleangebot={stelleangebot}
								key={stelleangebot.stelle_ID}
							/>
						);
				  })
				: null}
			{stellenangebots !== null ? <BewerbungsKontakt /> : null}
		</PageSectionContainer>
	);
};

// Styles for PageSection
const PageSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 40rem;
	justify-content: space-between;
	background-color: white;
	margin: 0.5rem 0;
	position: relative;
	align-items: center;
	padding-bottom: 1.5rem;

	.anchor {
		position: absolute;
		top: -9rem;
		opacity: 0;
		@media (max-width: 525px) {
			top: -12rem;
		}
	}

	.intersection-area {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 25rem;
		z-index: -3;
		background-color: red;
		opacity: 0;
	}

	.intersection-area-with-subsections {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 50rem;
		z-index: -2;
		background-color: red;
		opacity: 0;
	}

	.kontakt-impressum {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		height: fit-content;
		justify-content: space-between;
		padding: 2rem 4rem;
		@media (max-width: 920px) {
			flex-direction: column;
		}

		.kontakt-title-container {
			display: flex;
			width: 100%;
			padding: 2rem 0;
			.kontakt-title {
				width: 100%;
				text-align: left;
			}
		}

		.kontakt {
			width: 50%;
			@media (max-width: 920px) {
				margin-bottom: 2rem;
				width: 100%;
			}
			li {
				p {
					font-weight: 400;
				}
			}
		}
		.impressum {
			width: 50%;
			height: fit-content;
			@media (max-width: 920px) {
				margin-top: 2rem;
				width: 100%;
			}
			h4 {
				display: flex;
				width: 100%;
				font-weight: 700;
				font-size: 1.8rem;
			}
			p {
				font-weight: 400;
			}
		}
	}
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 2.5rem 4rem;
`;

const LinkToContainer = styled.ul`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: end;
	padding-left: 1rem;
	margin-bottom: 2rem;

	li {
		display: flex;
		width: 50%;

		a {
			display: flex;
			font-weight: 600;
			text-align: left;
			text-decoration: none;
			text-transform: uppercase;
			color: #807974;
			gap: 0.5rem;
			transition: color 0.2s ease-in-out;

			.link-to-arrow {
				font-size: 1.5rem;
				line-height: 4rem;
				font-weight: 700;
				margin-right: 1rem;
				transition: transform 0.2s ease-in-out;
			}

			&:hover {
				color: ${colors.themeBlue};
				.link-to-arrow {
					transform: translateX(0.5rem);
				}
			}
		}
	}
`;

const ReferenceLinksContainer = styled.ul`
	-webkit-column-span: all;
	-moz-column-span: all;
	column-span: all;
	column-count: 2;
	-moz-column-count: 2;
	column-gap: 2rem;
	column-width: 400px;
	column-fill: balance;
	-moz-column-fill: balance;
	padding: 2rem 4rem;
	margin-bottom: 2rem;
	li {
		a {
			color: black;
			font-weight: 700;
		}
		a:hover {
			color: #0054a5;
			span {
				display: inline-block;
				font-size: 1.5rem;
			}
		}
		@media (min-width: 525px) {
			span {
				display: none;
			}
		}
	}
`;

export default PageSection;
