import React, { useContext, useState, useEffect } from 'react';
import LanguageContext from '../context/context';
import useNavigation from '../hooks/useNavigation';
import * as _ from 'lodash';

import styled from '@emotion/styled';

const Footer = () => {
	const { language } = useContext(LanguageContext);
	const result = useNavigation();
	const [navSections, setNavSections] = useState([]);
	const [activeSection, setActiveSection] = useState(null);

	useEffect(() => {
		setNavSections(result);
	}, []);

	return (
		<FooterContainer className="footer">
			<NavigationFooterContainer className="navigation-menu-footer">
				<NavigationFooter>
					{_.sortBy([...navSections], ['nav_menu_position']).map(section =>
						section.sub_sections === null ? (
							<li
								key={section.nav_id}
								className={activeSection === section.nav_id ? 'active' : null}
								onClick={() => setActiveSection(section.nav_id)}
							>
								<a href={`#${section.nav_id}`}>
									{language === 'DE' ? section.title : section.titleEN}
								</a>
							</li>
						) : (
							<li
								key={section.nav_id}
								className={activeSection === section.nav_id ? 'active' : null}
								onClick={() => setActiveSection(section.nav_id)}
							>
								<a href={`#${section.nav_id}`}>
									{language === 'DE' ? section.title : section.titleEN}
								</a>
								<ul className="sublinks-list">
									{section.sub_sections.map(subSection => (
										<li
											key={subSection.nav_id}
											className={
												activeSection === subSection.nav_id ? 'active' : null
											}
										>
											<a href={`#${subSection.nav_id}`}>
												{language === 'DE'
													? subSection.title
													: subSection.title_EN}
											</a>
										</li>
									))}
								</ul>
							</li>
						),
					)}
				</NavigationFooter>
			</NavigationFooterContainer>
			<CopyContainer>
				<p className="copy">
					Copyright © 2022 <strong>flowTools</strong>. Alle Rechte vorbehalten.
				</p>
				<p className="author">
					Website entwickelt von{' '}
					<a href="https://www.pablonunezruiz.com" target="_blank">
						Pablo Núñez Ruiz
					</a>
				</p>
			</CopyContainer>
		</FooterContainer>
	);
};

const FooterContainer = styled.footer`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: fit-content;
	background-color: '#DADADC';
`;

const NavigationFooterContainer = styled.div`
	display: flex;
	width: 100%;
	height: fit-content;
	background-color: '#DADADC';
`;

const NavigationFooter = styled.ul`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	text-transform: uppercase;
	margin: 2rem 1rem;
	background-color: '#DADADC';

	li {
		display: flex;
		background-color: transparent;
		margin: 1.5rem;
		flex-direction: column;
		.sublinks-list {
			display: flex;
			flex-direction: column;
			width: 100%;
			li {
				display: flex;
				width: 100%;
				align-items: left;
				margin: 0;
				@media screen and (max-width: 650px) {
					margin: 0.5rem 0;
				}
				a {
					display: flex;
					font-weight: 400;
					text-align: left;
					width: 100%;
				}
			}
		}
		a {
			color: black;
			font-size: 1.4rem;
			font-weight: 600;
		}
	}
`;

const CopyContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: fit-content;
	margin: 1.5rem 0 3rem 0;
	align-items: center;
	justify-content: center;

	p {
		text-align: center;
		width: fit-content;

		a {
			color: black;
			font-weight: 600;
			font-size: 1.3rem;
		}

		a:hover {
			color: #0054a5;
		}

		strong {
			color: #0054a5;
		}
	}

	.copy {
		font-size: 1.5rem;
	}

	.author {
		font-size: 1.3rem;
		border-top: 1px solid black;
		margin-top: 1rem;
		padding-top: 0.5rem;
	}
`;

export default Footer;
