import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const Description = ({ title, text }) => {
	const [paragrafs, setParagrafs] = useState([]);

	const unicKeyGenerator = str => {
		let key = 0;
		for (let i = 0; i < str.length; i++) {
			key += str.charCodeAt(i);
		}
		key += Math.random();
		key += str.length;
		return key;
	};

	useEffect(() => {
		const theText = String(text);
		if (theText.indexOf('\n') !== -1) {
			const formatted = theText.split(`\n`).map(paragraf => {
				return (
					<DescriptionTextBrakes
						className="description-text-brakes"
						key={unicKeyGenerator(theText)}
					>
						{paragraf}
					</DescriptionTextBrakes>
				);
			});
			setParagrafs(<DescriptionText>{formatted}</DescriptionText>);
		} else {
			setParagrafs(<DescriptionText>{theText}</DescriptionText>);
		}
	}, [text]);

	return (
		<DescriptionContainer className="description-container">
			{paragrafs === null ? null : paragrafs}
		</DescriptionContainer>
	);
};

const DescriptionContainer = styled.div`
	display: flex;
	padding: 2rem 4rem;
	width: 100%;
	min-height: 15rem;
	& .description-container {
		/* -webkit-column-span: all;
		-moz-column-span: all;
		column-span: all; */
		column-count: 2;
		-moz-column-count: 2;
		column-gap: 3rem;
		column-width: 40%;
		-moz-column-fill: balance;
		column-fill: balance;
		font-weight: 400;
	}
	& .partner-description-container {
		column-count: 1;
		-moz-column-count: 1;
		column-width: 100%;
		-moz-column-fill: auto;
		column-fill: auto;
	}
`;

const DescriptionTextBrakes = styled.p`
	font-family: 'Source Sans Pro', sans-serif !important;
	font-size: 2.5rem !important;
	line-height: 3.5rem !important;
	flex: 1;
	font-weight: 400 !important;
	margin-bottom: 2rem !impportant;
	break-inside: avoid-column !important;
`;

const DescriptionText = styled.div`
	font-family: 'Source Sans Pro', sans-serif !important;
	font-size: 2.5rem !important;
	line-height: 3.5rem !important;
	font-weight: 400;
	margin-bottom: 2rem;
	/* & .partner-description {
		column-count: 1;
		-moz-column-count: 1;
		column-width: 100%;
	} */
	column-count: 2;
	-moz-column-count: 2;
	column-gap: 3rem;
	column-width: 40%px;
	-moz-column-fill: balance;
	column-fill: balance;
	font-weight: 400;

	@media (max-width: 700px) {
		column-count: 1;
	}
`;

export default Description;
