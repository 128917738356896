import { graphql, useStaticQuery } from 'gatsby';

const useSections = () => {
	const result = useStaticQuery(
		graphql`
			query {
				allStrapiPageSection(filter: { show_in_page: { eq: true } }) {
					nodes {
						stellenangebots {
							role_name
							job_description
							tasks
							requierements
							benefits
							stelle_ID
						}
						id
						position
						Description_text
						Description_text_EN
						Subtitle
						Subtitle_EN
						Title
						Title_EN
						kontakt {
							Adresse
							Fax
							Firma
							Telefon
							Website
							email
						}
						impressum {
							Inhalt
							Inhalt_EN
							Titel
							Titel_EN
							id
						}
						image {
							file {
								childImageSharp {
									gatsbyImageData(width: 1280)
								}
							}
						}
						nav_id
						reference_links {
							id
							link
							linktitle
							reference_text
							reference_text_EN
						}
						link_to {
							Title
							Title_EN
							nav_id
						}
						sub_sections {
							Order
							show_on_summary
							Summary_text
							Summary_text_EN
							description_text
							description_text_EN
							id
							link_to {
								Title
								Title_EN
								id
								nav_id
							}
							reference_links {
								id
								link
								linktitle
								reference_text
								reference_text_EN
							}
							subtitle
							subtitle_EN
							title
							title_EN
							nav_id
							subsection_img {
								file {
									childImageSharp {
										gatsbyImageData(
											width: 1280
											blurredOptions: { toFormat: PNG }
										)
									}
								}
							}
						}
					}
				}
			}
		`,
	);

	return result.allStrapiPageSection.nodes.map(section => ({
		title: section.Title,
		titleEN: section.Title_EN,
		subtitle: section.Subtitle,
		subtitleEN: section.Subtitle_EN,
		description: section.Description_text,
		descriptionEN: section.Description_text_EN,
		impressum: section.impressum,
		kontakt: section.kontakt,
		image: section.image,
		nav_id: section.nav_id,
		reference_links: section.reference_links,
		link_to: section.link_to,
		id: section.id,
		position: section.position,
		sub_sections: section.sub_sections,
		stellenangebots: section.stellenangebots,
	}));
};

export default useSections;
