import React from 'react';
import styled from '@emotion/styled';

const Header = () => {
	return (
		<HeaderContainer className="header" id="header">
			<HeaderTitle className="header-title-container">
				<h1 className="header-title">
					<a href="#home">
						flow<span>Tools</span>
					</a>
				</h1>
				<h2 className="header-subtitle">workflow integration development</h2>
			</HeaderTitle>
		</HeaderContainer>
	);
};
const HeaderContainer = styled.header`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 18rem;
	@media screen and (max-width: 524px) {
		display: none;
	}
`;

const HeaderTitle = styled.div`
	display: flex;
	flex-direction: column;
	width: 42.5rem;
	justify-content: center;
	margin-left: 4rem;
	@media screen and (max-width: 524px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: fit-content;
	}
	.header-title {
		a {
			font-family: 'Encode Sans', sans-serif;
			font-size: 4.5rem;
			color: #aba29b;
			margin: 0;
			font-weight: 500;
			span {
				color: #0b59a9;
				font-weight: 500;
			}
		}
	}
	.header-subtitle {
		font-size: 2.2rem;
		text-transform: none;
		color: #1369a9;
		text-align: right;
		@media screen and (max-width: 524px) {
			display: none;
		}
	}
`;

export default Header;
