import React, { useContext } from 'react';
import LanguageContext from '../context/context';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const SummarySquare = ({ section, themeColor }) => {
	const { title, title_EN, Summary_text, Summary_text_EN, nav_id } = section;
	const { language, languages } = useContext(LanguageContext);

	return (
		<Square
			key={nav_id}
			className={`${nav_id}-square summary-square`}
			css={css`
				background-color: ${themeColor};
			`}
		>
			<a href={`#${nav_id}`}>
				<SquareTitle>
					{language === languages.DE ? title : title_EN}
				</SquareTitle>
				<SquareText>
					{language === languages.DE ? Summary_text : Summary_text_EN}
				</SquareText>
			</a>
		</Square>
	);
};

const Square = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: left;
	justify-content: center;
	height: 22rem;
	width: 22rem;
	margin: 1rem 1rem;
	font-size: 0.2em;
	justify-self: center;
	align-self: center;
	transition: transform 0.15s ease-in;

	a {
		width: 100%;
		height: 100%;
		padding: 1.5rem;
	}

	&:hover {
		transform: scale(1.1);
	}

	@media screen and (max-width: 400px) {
		height: 18rem;
		width: 18rem;
	}
	a {
		text-decoration: none;
		color: white;
		display: flex;
		flex-direction: column;
	}
`;
const SquareTitle = styled.h2`
	font-size: 0.85em;
	font-weight: 600;
	width: 100%;
	height: 50%;

	@media screen and (max-width: 400px) {
		font-size: 0.75em;
	}
`;

const SquareText = styled.h5`
	font-size: 0.75em;
	text-transform: none;
	font-weight: 400;
	width: 100%;
	height: 50%;
	text-wrap: wrap;

	@media screen and (max-width: 400px) {
		font-size: 0.7em;
	}
`;

export default SummarySquare;
