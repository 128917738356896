import { graphql, useStaticQuery } from 'gatsby';

const useRecruitment = () => {
	const result = useStaticQuery(graphql`
		query {
			strapiRecruiting {
				link_to_jobs
				recruiting_text
				recruiting_text_EN
				show_on_page
			}
		}
	`);

	return result.strapiRecruiting;
};

export default useRecruitment;
