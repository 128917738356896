import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import { colors } from '../theme/theme';

const Stellenangebot = ({ stelleangebot }) => {
	const { role_name, job_description, tasks, requierements, benefits } =
		stelleangebot;
	return (
		<StellenangebotContainer>
			<h4>{role_name}</h4>
			<ReactMarkdown>{job_description}</ReactMarkdown>
			<ReactMarkdown>{tasks}</ReactMarkdown>
			<ReactMarkdown>{requierements}</ReactMarkdown>
			<ReactMarkdown>{benefits}</ReactMarkdown>
		</StellenangebotContainer>
	);
};

const StellenangebotContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 90%;
	font-family: 'Source Sans Pro', sans-serif;
	margin-bottom: 2rem;
	padding: 2rem 5rem;
	border-top: solid 0.5rem ${colors.themeBlue};

	h4 {
		font-size: 3rem;
		font-weight: 700;
		margin-bottom: 2rem;
	}

	h5 {
		font-size: 2.3rem;
		font-weight: 700;
		margin: 3rem 0 1rem 0;
	}

	h4,
	h5 {
		color: ${colors.themeBlue};
	}

	p {
		font-size: 2.3rem;
	}

	li {
		margin: 2rem 0;
		font-size: 2rem;
		list-style-type: square;
	}
`;

export default Stellenangebot;
