import React from 'react';

export const languages = {
	DE: 'DE',
	EN: 'EN',
};

const LanguageContext = React.createContext(languages);

export default LanguageContext;
