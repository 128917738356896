import React from 'react';
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';

import './Layout.css';
import 'normalize.css';

const Layout = ({ children }) => {
	return (
		<div className="layout" id="header">
			<Header />
			<Navigation />
			{children}
			<Footer />
		</div>
	);
};

export default Layout;
