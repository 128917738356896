import { graphql, useStaticQuery } from 'gatsby';

export const useBewerbungsKontakt = () => {
	const result = useStaticQuery(
		graphql`
			query {
				allStrapiBewerbungsKontakt {
					nodes {
						email_text
						email_adress
						telephone_text
						telephone_number_to_show
						telephone_number_to_call
						id
					}
				}
			}
		`,
	);

	return result.allStrapiBewerbungsKontakt.nodes[0];
};
