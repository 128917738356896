import styled from '@emotion/styled';

// Styles for Navigation
export const MobileTitle = styled.h1`
	a {
		font-family: 'Encode Sans', sans-serif;
		font-size: 3.5rem;
		color: #aba29b;
		margin: 0;
		font-weight: 500;
		span {
			color: #0b59a9;
			font-weight: 500;
		}
	}
	@media screen and (min-width: 525px) {
		display: none;
	}
`;

export const NavigationContainer = styled.div`
	display: flex;
	padding: 1rem 0;
	background: white;
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;
	z-index: 10;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5.5rem;
	@media screen and (max-width: 1100px) {
		flex-direction: row-reverse;
		height: 5rem;
	}
	@media screen and (max-width: 525px) {
		justify-content: space-around;
	}
`;

export const MobileNavigationMenu = styled.nav`
	display: flex;

	@media screen and (min-width: 1100px) {
		display: none;
	}

	.mobile-nav-icon {
		display: none;
		@media screen and (max-width: 1100px) {
			display: flex;
			position: absolute;
			right: 2.5rem;
		}

		@media screen and (min-width: 525px) {
			top: 0.5rem;
		}

		@media screen and (max-width: 525px) {
			top: 0.5rem;
		}
	}
	.mob-nav-closed {
		transform: scale(1, 0);
		transform-origin: top;
		transition: transform 0.2s ease-in-out;
		li {
			opacity: 0;
			transition: opacity 0.1s ease-in-out;
		}
	}
	.mob-nav-open {
		transform: scale(1, 1);
		transform-origin: top;
		transition: transform 0.3s ease-in-out;
		li {
			opacity: 1;
			transition: opacity 0.2s ease-in-out 0.3s;
		}
	}
`;

export const MobileNavigationList = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: absolute;
	top: 5rem;
	left: 0;
	width: 100%;
	height: 50vh;
	min-height: fit-content;
	background-image: linear-gradient(
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0.95)
	);
	padding-left: 40%;
	@media (orientation: landscape) {
		height: fit-content;
	}

	.nav-active {
		a {
			color: #615c58;
		}
	}
	li {
		text-transform: uppercase;
		margin: 0 0.7rem;
		display: flex;
		flex-direction: row;
		height: fit-content;
		@media screen and (max-width: 1100px) {
			margin: 0.5rem 0;
		}
		@media (orientation: landscape) {
			margin: 1rem 0;
		}
		.arrow-nav {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			font-size: 1rem;
			margin-right: 0.5rem;
		}
		a {
			color: #615c58;
			font-size: 1.8rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		a:hover {
			color: #004ea2;
		}
	}
`;

export const NavigationMenu = styled.nav`
	display: flex;
	flex-direction: row;
	max-width: 1000px;
	margin: 0 2rem 0 4rem;
	justify-content: space-between;
	@media screen and (max-width: 1100px) {
		display: none;
	}
`;

export const NavigationList = styled.ul`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;

	li {
		display: flex;
		text-transform: uppercase;
		margin: 0 0.7rem;
		.is-on-screen {
			position: relative;
			color: #0054a5;
		}

		a::after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			right: 0;
			height: 3px;
			background: #0054a5;
			transform: scale(0, 1);
			transition: transform 0.2s ease-in-out;
		}
		a.is-on-screen::after {
			transform: scale(1, 1);
		}
		.arrow-nav {
			display: flex;
			align-items: center;
			height: 100%;
			font-size: 1rem;
			line-height: 1.5rem;
			margin-right: 0.5rem;
			transition: transform 0.2s linear;
		}
		a {
			position: relative;
			color: #615c58;
			font-size: 1.8rem;
			font-weight: 600;
		}
		a:hover {
			color: #004ea2;
		}
		&:hover {
			.arrow-nav {
				transform: translateX(0.25rem);
			}
		}
	}
`;

// To show the Language Switcher: display: flex;
// To hide the Language Switcher: display: none;
export const LanguageSwitcher = styled.div`
	display: none;
	width: 10rem;
	justify-content: space-around;
	align-items: center;
	margin: 0 2rem;
	.active {
		color: #004ea2;

		p {
			font-weight: 700;
		}
	}
`;

export const LanguageButton = styled.button`
	display: flex;
	width: 5rem;
	height: 3rem;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 1.8rem;
	font-weight: 500;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border: none;
	color: #8a8a8a;
	cursor: pointer;
	p {
		font-size: 1.8rem;
		display: flex;
		align-items: center;
		span {
			font-size: 1rem;
			line-height: 1.5rem;
			margin-right: 0.5rem;
		}
	}
`;
