import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { subSecColor } from '../hooks/useColors';
import useHome from '../hooks/useHome';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import useSections from '../hooks/useSections';
import SummarySquare from './SummarySquare';
import { css } from '@emotion/react';
import * as _ from 'lodash';

const Home = () => {
	const result = useSections();
	const image = useHome();
	const [summarySections, setSummarySections] = useState([]);

	const img = getImage(image.home_image.file.childImageSharp.gatsbyImageData);

	useEffect(() => {
		const filteredSections = result.filter(section => section.sub_sections);
		const subsections = filteredSections.map(section => section.sub_sections);
		const subsectionsReady = subsections.flat();
		const subsectionsToShow = subsectionsReady.filter(
			subsection => subsection.Summary_text !== null,
		);

		setSummarySections(subsectionsToShow);
	}, []);

	return (
		<HomeContainer className="home">
			<div className="home-intersection-area" id="home">
				IntersectionArea
			</div>
			<GatsbyImage
				css={css`
					width: 100%;
				`}
				image={img}
				alt="home image"
			/>
			<HomeContent>
				<SummaryContainer className="summary-container">
					{_.sortBy([...summarySections], ['Order']).map(subsection => (
						<SummarySquare
							key={subsection.id}
							section={subsection}
							themeColor={subSecColor(subsection.Order)}
						/>
					))}
				</SummaryContainer>
			</HomeContent>
		</HomeContainer>
	);
};

const HomeContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: fit-content;
	justify-content: center;
	align-items: center;
	@media (max-width: 920px) {
		height: fit-content;
	}
	.home-intersection-area {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 50%;
		opacity: 0;
		z-index: -1;
	}
`;

const HomeContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 600px;
	min-height: 40rem;
	justify-content: center;
	align-items: center;
	background-color: white;
`;

const SummaryContainer = styled.div`
	/* display: flex; */
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	margin: 0.5rem 0;
	/* flex-flow: row wrap; */
	width: fit-content;
	height: fit-content;
	/* justify-content: flex-start;
	align-items: center; */
	background-color: white;

	@media (max-width: 1210px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (max-width: 1100px) {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}

	@media (max-width: 980px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (max-width: 600px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 330px) {
		grid-template-columns: 1fr;
	}
`;

export default Home;
